import React, {createContext, useContext } from 'react';

const ChatContext = createContext();

export default function ChatProvider({children, chatHandlers}) {
  return (
    <ChatContext.Provider value={chatHandlers}>
      { children }
    </ChatContext.Provider>
  );
}

function useChat() {
  return useContext(ChatContext);
}

export { useChat };