import React from 'react';
import './Header.sass'
import Button from "./Button";
import {useSidebarDispatch, useSidebarState} from "../store/SidebarProvider";
import {useStreamInfo} from "../store/StreamInfoContext";
import Parser from 'html-react-parser';
import {useChat} from "../store/ChatProvider";

export default function Header() {
	const sidebarDispatch = useSidebarDispatch();
	const sidebarState = useSidebarState();
	const streamInfo = useStreamInfo();
	const chatHandlers = useChat();

	const toggleSupport = () => {
		if (chatHandlers.isOpen()) {
			chatHandlers.close();
			chatHandlers.hide();
		} else {
			chatHandlers.show();
			chatHandlers.open();
		}
	}

	return (
		<header className="header">
			<div className="header-left">
				{streamInfo["show_program"] && <Button
					onClick={() => sidebarDispatch({ type: 'leftSidebarOpen', open: !sidebarState.leftSidebarOpened })}
				>
					{ sidebarState.leftSidebarOpened ? streamInfo["hide_program"] : streamInfo["show_program"] }
				</Button> }
				{
					streamInfo["directory_button"] ?
						<Button onClick={ () => window.location.href = streamInfo["directory_href"] }>
							{ streamInfo["directory_button"] }
						</Button>
						: null
				}
			</div>
			<div className="header-center">
				<span className="header-title">{Parser(streamInfo.header_title)}</span>
			</div>
			<div className="header-right">
				{streamInfo["show_exhibition"] && <Button
					onClick={() => sidebarDispatch({ type: 'rightSidebarOpen', open: !sidebarState.rightSidebarOpened })}
				>
					{ sidebarState.rightSidebarOpened ? streamInfo["hide_exhibition"] : streamInfo["show_exhibition"] }
				</Button> }
				{
					streamInfo["support_button"] ?
						<Button onClick={toggleSupport}>
							{ streamInfo["support_button"] }
						</Button>
						: null
				}
			</div>
		</header>
  );
}
